<template>
  <div>Closing</div>
</template>

<script>
export default {
  name: 'Closing',
  mounted() {
    console.log(this.$route.meta)
    this.$http
      .post('admin/processes/' + this.$route.meta.process, []) // TODO: on service ?
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.error(error)
      })
  }
}
</script>
